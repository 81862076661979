.form-label {
  margin: 0.5em;
}

.form-container {
  border: 2px solid #fff;
  background-color: #ececed;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
}
.result-container {
  background-color: #ececed;
  padding: 20px;
  border: 2px solid #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
